import { Directive, Input, Output, EventEmitter,Inject,ElementRef,OnInit } from '@angular/core';
import {Observable } from 'rxjs';


@Directive({
    selector: '[label]',
    styles:[ ".underline{text-decoration: underline;}"]
   
})
export class DyLabelDirective implements OnInit {
   @Input() label: any; // stored value
    private control:JQuery;
    constructor( @Inject(ElementRef) elementRef: ElementRef) {
        this.control = $(elementRef.nativeElement);

    
        

   }

    ngOnInit() {
       

        var formGroup = $(`<div class="form-group"></div>`);
        this.control.before(formGroup);
        var contDiv = $("<div></div>");
        if (this.control.attr("width") != "") {
            formGroup.css("width", this.control.attr("width"));
        }

        var cls = "label label-primary";
        if (this.control.attr("label-class") != undefined) {
            cls = this.control.attr("label-class");
        }


        formGroup.append(`<h4><label class="${cls}" >${this.label}</label></h4>`)
        formGroup.append(contDiv);
        contDiv.append(this.control); 
        
        //this.control.prepend(`<label class="label label-default" >${this.label}</label>`);
    }
}