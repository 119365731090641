import {Directive, Component,  ElementRef, Renderer, Provider,forwardRef, AfterViewInit, Input, OnInit, Inject, EventEmitter, Output, ViewChild} from '@angular/core';
import {DyModelDirective} from '../Directives/DyModel'
import {ReadOnlyControl} from "../Interfaces/ReadOnlyControl"
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'
import { DxChartComponent } from 'devextreme-angular';
import { DxiChartSeries } from 'devextreme-angular/ui/nested/base/chart-series-dxi';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DateRange, DateRangePickerComponent } from './DateRangePicker';
import { DyDropDownComponent } from './DropDown';
import { MoneyPipe } from '../Pipes/Pipes';


export class Data {
            day: string;
            oranges: number;
        }

@Component({
    selector: 'BBChart',
    //properties: ["value:value"],
   
    //events: ["dyModelChange"],
    template: `
<div class="form-inline">
<BBDateRangePicker label="Date Range" #dateRange (DateRangeChanged)="dateChanged()"></BBDateRangePicker><DyDropDown (change)="dateChanged()" label="Grouping" [options]='[{ value: "weekly", text: "Weekly" }, { value: "daily", text: "Daily" }, { value: "monthly", text: "Monthly" }]' #grouping></DyDropDown>
<div id="buttonGroup">
    <dx-button
        icon="print"
        text="Print"
        (onClick)="print()">
    </dx-button>
    <dx-button
        icon="export"
        text="Export"
        (onClick)="export()">
    </dx-button><div>
<dx-chart   palette="soft"
    id="chart" #chart  >

  <dxo-tooltip
        [enabled]="false"
                [customizeTooltip]="customizeTooltip">
    </dxo-tooltip>
</dx-chart>

</div>
`
    
})
export class BBChartComponent implements AfterViewInit  {

    private ngUnsubscribe: Subject<any> = new Subject();
    private http: HttpClient;
    @ViewChild("grouping", { static: true }) grouping: DyDropDownComponent
    @ViewChild("dateRange", { static: true }) dateRangePicker: DateRangePickerComponent;
    ngOnDestroy(): any {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public dateChanged = () => {
        console.log("changed");
        this.chart.instance.showLoadingIndicator();
        this.http.get(`/chart/writtenInvoices/?start=${this.dateRangePicker.DateRange.start.format('MM/DD/YYYY')}&end=${this.dateRangePicker.DateRange.end.format('MM/DD/YYYY')}&Grouping=${this.grouping.value}`).takeUntil(this.ngUnsubscribe).subscribe((res) => {
            console.log(this.dateRangePicker.StartDate);

            this.chart.dataSource = res[0];
            this.chart.instance.hideLoadingIndicator();
        })
    }
    print() {
        this.chart.instance.print();

    }

    export() {
        this.chart.instance.exportTo(<any>(this.chart.title).text, "png");
    }

    customizeTooltip(arg: any) {
        console.log(arg);
        return {
           
            text: arg.argument + ' - ' + arg.seriesName + "<br/>" + accounting.formatMoney(arg.valueText, null, 2)         };
    }

    ngAfterViewInit(): void {

        
        //this.http.get(`/chart/writtenInvoices/?start=${'01/01/2018'}&end=${moment().format('MM/DD/YYYY')}`).takeUntil(this.ngUnsubscribe).subscribe((res) => {
        //    console.log(res);

        //    this.chart.dataSource = res[0];
        //}
        this.dateRangePicker.DateRange = {start: moment().startOf('month'),end: moment().endOf('month')}
        this.grouping.options = [{ value: "week", text: "Weekly" }, { value: "day", text: "Daily" }, { value: "month", text: "Monthly" }]
        this.grouping.value = "weekly";
        var series = { argumentField: "argument", valueField: "value",  type: "bar" }
        this.chart.title = { text: `Sales breakdown ${this.grouping.value}`};
        var newChart = { series: series }
        this.chart.adaptiveLayout.
        //this.chart.title = `Revenew breakdown by ${this.grouping.value}`;
        this.chart.tooltip = { enabled: true, customizeTooltip:this.customizeTooltip}
        this.chart.commonSeriesSettings = series;
        this.chart.seriesTemplate = { nameField: "series" };
        this.chart.adaptiveLayout = { height: "900" };
        this.chart.instance.render();

        //let data: Data[] = [{
        //    day: "Monday",
        //    oranges: 3
        //}, {
        //    day: "Tuesday",
        //    oranges: 2
        //}, {
        //    day: "Wednesday",
        //    oranges: 3
        //}, {
        //    day: "Thursday",
        //    oranges: 4
        //}, {
        //    day: "Friday",
        //    oranges: 6
        //}, {
        //    day: "Saturday",
        //    oranges: 11
        //}, {
        //    day: "Sunday",
        //    oranges: 4
        //}];
        //this.chart.dataSource = data;
    }

    public change = () => {
        let data: Data[] = [{
            day: "Monday",
            oranges: 1
        }, {
            day: "Tuesday",
            oranges: 11
        }, {
            day: "Wednesday",
            oranges: 9
        }, {
            day: "Thursday",
            oranges: 7
        }, {
            day: "Friday",
            oranges: 1
        }, {
            day: "Saturday",
            oranges: 3
        }, {
            day: "Sunday",
            oranges: 6
        }];
        this.chart.dataSource = data;
        
    }
    public elementRef: ElementRef;
    @ViewChild("chart", { static: true }) chart: DxChartComponent;
    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(HttpClient) http: HttpClient) {
        this.elementRef = elementRef; 
       
        this.http = http;
    }

   
    
}