import {Directive, Component,  ElementRef, Renderer, Provider,forwardRef, AfterViewInit, Input, OnInit, Inject, EventEmitter, Output} from '@angular/core';

import {DyModelDirective} from '../Directives/DyModel'
import {ReadOnlyControl} from "../Interfaces/ReadOnlyControl"
import {  ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {

    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => YesNoComponent),
    multi: true
};

//import {Http, HTTP_PROVIDERS, URLSearchParams} from '@angular/http';



@Component({
    selector: 'DyYesNo',
    //properties: ["value:value"],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    //events: ["dyModelChange"],
    
    template: `<input  class="form-control" type="checkbox" [attr.disabled]="readOnly ? '' : null" [(ngModel)]="value" />`
    
})
export class YesNoComponent implements AfterViewInit, ControlValueAccessor, ReadOnlyControl {
    elementRef: ElementRef;
    @Input() readOnly: boolean;

    @Input("model") model: any;
    private datePicker: any;
    private datePickerInput: any;
    private _value: any = "";
    @Output() modelChange: EventEmitter<any> = new EventEmitter()
    private checkBox: JQuery;
   // @Input("readOnly") readOnly: Boolean;
    private _onTouchedCallback: (_: any) => void = (a) => { };

    private _onChangeCallback: (_: any) => void = (a) => { };
    constructor( @Inject(ElementRef) elementRef: ElementRef) {
        this.elementRef = elementRef; 
        
        //this.httpService = http;
    }
    ngAfterViewInit() {
        var natEle = jQuery(this.elementRef.nativeElement);
        this.checkBox = natEle.find("input");
        if (this.value == true) {
            this.checkBox.attr("checked", "true") 
            this.setCheckBox();
        }
        this.checkBox.checkboxpicker().change((data) => {

            if ((<any>data.target).checked == true) {
                this.value = true;
            }
            else {
                this.value = false;
            }
        });
        
    }
    get value(): any { return this._value; };

    //set accessor including call the onchange callback the checked is backwards accounting for this.
    set value(v: any) {
        if (v != this.value) {
            this._value = v;
            this._onChangeCallback(v);
        }
    }
    onTouched() {
        this._onTouchedCallback(null);
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {

        this._value = value;
        if (value == undefined || value == null) {
            return;
        }

        this.setCheckBox();
    }

    public setCheckBox = () => {
        if (this.checkBox != undefined) {
            if (this.value == true) {
                this.checkBox.prop('checked', true);
            }
            else {
                this.checkBox.prop('checked', false);
            }
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }

   
    
}