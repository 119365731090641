import {Directive, Component, ElementRef, Renderer, Input, OnInit, Inject, EventEmitter, Output, Provider, forwardRef} from '@angular/core';


import {ReadOnlyControl} from "../Interfaces/ReadOnlyControl"


//import {Http, HTTP_PROVIDERS, URLSearchParams} from '@angular/http';

@Component({
    selector: 'DyTreeView',
    
    //events: ["dyModelChange"],
    template: `<div style="overflow:auto;height:300px; " id="Tree">

    </div>`

})
export class TreeViewComponent implements OnInit {
    elementRef: ElementRef;
    treeview: any;
    finalTv: TreeView;
    @Input() nodes: Array<TreeNode>;
    @Input("showCheckBoxes") showCheckBoxes: boolean = false;
    constructor( @Inject(ElementRef) elementRef: ElementRef) {
        this.elementRef = elementRef;

        //this.httpService = http;
    }
    
    
    ngOnInit() {

        
        var natEle = jQuery(this.elementRef.nativeElement);
        var item = natEle.find("div");
        this.treeview = item;
        this.createTree();

    }

    public createTree = () => {
        this.finalTv = this.treeview.treeview({ data: this.nodes, levels: 1, showCheckbox: this.showCheckBoxes });
        // console.log(this.treeview.treeview("nodes"));

        //this.treeview.treeview(true).addNode([{ text: "hi" }],tree[0]);

        this.treeview.on("nodeSelected", this.nodeSelected).on("nodeExpanded", this.nodeExpanded);
    }

    public getNodes = ():Array<TreeNode> => {
        return this.treeview.treeview(true).getNodes();

    }


    public updateNode = (oldNode:TreeNode,newNode:TreeNode) => {
        this.treeview.treeview(true).updateNode(oldNode, newNode);
    }

    public getSelectedNode = ():TreeNode => {
        return this.treeview.treeview(true).getSelected();
    }

    public getCheckedNodes = (): TreeNode[] => {
        return this.treeview.treeview(true).getChecked();
    }


    public getNode = (nodeId:string):TreeNode => {
        var t = this.treeview.treeview(true).getNodes(nodeId);
        var found;
        $.each(t, (i, v: TreeNode) => {
            if (v.nodeId == nodeId) {
                found = v;
                return false;
            }
        })
        return found;
    }

    public  addNode =(parent: TreeNode, node: TreeNode | Array<TreeNode>) => {
        this.treeview.treeview(true).addNode(node, parent);
    }

    public nodeSelected = (event, node: TreeNode) => {
        if (node.onSelected != undefined) {
            node.onSelected(node);
        }
    }

    public nodeExpanded = (event, node: TreeNode) => {
        if (node.onExpanded != undefined) {
            node.onExpanded(node);
            var tv:any = this.treeview;
            //tv.treeview("setInitialStates", { nodes: tv.treeview().tree }, 0);
            //tv.treeview("render");
        }
    }
    //get accessor
   
    
}

