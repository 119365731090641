
import * as Raven from 'raven-js';
import { NgModule, enableProdMode, ErrorHandler,Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
//import { HttpClientModule } from '@angular/Common/http';

import { PipesModule } from "../../Common/Pipes/Pipes.Module"
//import { ControlsModule } from "../../Common/Controls/Controls.Module";
import { DirectivesModule } from "../../Common/Directives/Directives.Module";
import { AppComponent } from "./FullApp.component";
import { HomeComponent } from "./Home.component";
import { RouterModule, Routes, UrlSegment, UrlSegmentGroup, Route, DefaultUrlSerializer, UrlTree, UrlSerializer } from '@angular/router';
import { SimpleControlsModule } from '../../Common/Controls/SimpleControl.Module';
import "zone.js";
import { GridComponent } from '../../Common/Controls/Grid';
import { HttpClientModule } from '@angular/common/http';
import { DxTemplateModule, DxDataGridModule, DxChartModule, DxButtonModule, DxToolbarModule, DxHtmlEditorModule, DxPopoverModule } from 'devextreme-angular';
import { DxoGridModule } from 'devextreme-angular/ui/nested';
import { RecordTypeService } from '../../Common/Services/RecordTypeService';
//


declare var environment: string;
declare var prodmodeSet:boolean 



//export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
//    parse(url: string): UrlTree {
//        // Optional Step: Do some stuff with the url if needed.

//        // If you lower it in the optional step 
//        // you don't need to use "toLowerCase" 
//        // when you pass it down to the next function
//        return super.parse(url.toLowerCase());
//    }
//}

//export class AppRoutes {
//    static appRoutes: Routes = [
//        {path:'home', component: HomeComponent, pathMatch: 'full' },

//        { path: '', redirectTo: "/home", pathMatch: "full" }


//    ];

//   static  CaseInsensitiveMatcher(url: string) {
//    url = url.toLowerCase();

//    return function (
//        segments: UrlSegment[],
//        segmentGroup: UrlSegmentGroup,
//        route: Route
//    ) {
//        let matchSegments = url.split('/');
//        if (
//            matchSegments.length > segments.length ||
//            (matchSegments.length !== segments.length && route.pathMatch === 'full')
//        ) {
//            return null;
//        }

//        let consumed: UrlSegment[] = [];
//        let posParams: { [name: string]: UrlSegment } = {};
//        for (let index = 0; index < segments.length; ++index) {
//            let segment = segments[index].toString().toLowerCase();
//            let matchSegment = matchSegments[index];

//            if (matchSegment.startsWith(':')) {
//                posParams[matchSegment.slice(1)] = segments[index];
//                consumed.push(segments[index]);
//            }
//            else if (segment === matchSegment) {
//                consumed.push(segments[index]);
//            }
//            else {
//                return null;
//            }
//        }

//        return { consumed, posParams };
//    }
//}

//}



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        PipesModule,
        //ControlsModule,
        DirectivesModule,
        ReactiveFormsModule,
        //OrderModule,
        DxTemplateModule, DxDataGridModule, DxChartModule, DxButtonModule, DxToolbarModule, DxHtmlEditorModule, DxPopoverModule,
         SimpleControlsModule,
        //RouterModule.forRoot([
        //    { path: '', component: HomeComponent },
        //], { enableTracing: true }  )
        //RouterModule.forRoot(
        //    AppRoutes.appRoutes,
        //    { enableTracing: true } // <-- debugging purposes only
        //)

    ],
    providers: [
//        {
    //    provide: UrlSerializer,
    //    useClass: LowerCaseUrlSerializer
        //}
        HttpClientModule,
        //RecordTypeService


    ],
    declarations: [AppComponent, HomeComponent,GridComponent],
    exports: [AppComponent, HomeComponent, GridComponent],
    entryComponents: [AppComponent,HomeComponent],
    bootstrap: [AppComponent]
})
export class FullAppModule {


}