import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
//import { HttpClientModule }    from '@angular/Common/http';
import {MoneyPipe,OrderBy,SqlDatePipe,ToPercent,ToProperCasePipe,UndefinedToEmptyPipe,EncodeUrl} from "./Pipes"
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
       // HttpClientModule,
    ],
    exports: [MoneyPipe, OrderBy, ToPercent, ToProperCasePipe, UndefinedToEmptyPipe, SqlDatePipe,EncodeUrl],
   
    declarations: [
        MoneyPipe, OrderBy, ToPercent, ToProperCasePipe, UndefinedToEmptyPipe, SqlDatePipe,EncodeUrl
    ],
    
})
export class PipesModule {
}