import { Directive, Input, Output, EventEmitter, Inject, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import {Observable } from 'rxjs';


@Directive({
    selector: '[showwidth]',
    //host: {
        
    //    "(input)": "ModelChange.next(this.objectModel)"
    //}
})
export class DyWidthField implements AfterViewInit {
    @Input() objectModel: any; // stored value
    @Input() modelWidthFieldName: any;
    @Input() modelQuantityFieldName: any;
    @Input() trackWidth: boolean;
    @Input() UOMName:string
    private control: JQuery;
    constructor(@Inject(ElementRef) elementRef: ElementRef) {
        //console.log("elementref");
       // console.log(elementRef);
        this.control = $(elementRef.nativeElement);




    }
   
    public options = {
        error: false, // Элемент отображаемый при ошибке
        comment: false, // Элемент отображаемы при редактировании
        calculatewrapper: false, // Элемент будет отображен при расчетах
        calculate: false, // Элемент куда будет выводится результат вычисления
        oncalculate: false, // Функция вызывается если введены [+,-,*,/,(,)]
        onendcalculate: false, // Функция вызывается если удалены все [+,-,*,/,(,)]
        onready: false, // Функция вызывается при подготовке элемента
        onfocus: false, // Функция вызывается при установке фокуса
        onblur: false, // Функция вызывается при потере фокуса (в функцию передается конечный результаты ввода)
        onerror: false, // Функция вызывается при попытке ввода запрещенных символов
        onenter: false, // Функция вызывается если нажата клавиша enter (вызывается ДО onblur)
        onescape: false, // Функция вызывается если нажата клавиша escape (вызывается ДО onblur)
        oninput: false, // Функция вызывается при вводе любого символа (в функцию передается введенный символ)
        ifnul: '0', // Символ вставляемый если введеные данные ошибочны или 0
        sign: true, // Показывать знак минус при вводе отрицательного значения
        fixed: 2
    };
    public costToString = (cost: any, fixed) => {
        var triadSeparator = ',';
        var decSeparator = '.';
        var minus = '&minus;';
        var num = '0';
        var numd = '';
        var fractNum = 2;
        fixed = (!fixed) ? fixed = 2 : fixed;
        if (this.options.fixed != undefined) {
            fixed = this.options.fixed;
        }
        var fixedTest = '00';
        if (fixed != 2) {
            fixedTest = '';
            for (var i = 0; i < fixed; i++) {
                fixedTest += String('0');
            }
        }
        if (!isNaN(parseFloat(cost))) {
            num = parseFloat(Math.abs(cost).toString()).toFixed(fixed).toString();
            numd = num.substr(num.indexOf('.') + 1, fixed).toString();
            num = parseInt(num).toString();
            var regEx = /(\d+)(\d{3})/;
            while (regEx.test(num)) {
                num = num.replace(regEx, "$1" + triadSeparator + "$2");
            }
            if (numd != fixedTest) {
                var lastZeros = /[0]*$/g;
                num += decSeparator + numd.replace(lastZeros, '');
            }
            if (cost < 0) num = '−' + num;
        }
        return num;
    }
    ngAfterViewInit() {
        var component = this;
        var nchars = new RegExp(/[\!\@\#\№\$\%\^\&\=\[\]\\\'\;\{\}\|\"\:\<\>\?~\`\_A-ZА-Яa-zа-я]/);
        var achars = "1234567890+-/*,. ";
        var errTimer = undefined;
        var inObj = this.control.find("input");
        inObj.addClass("showWidth");

        var elemW = 68;
        var oldVal = 0;
        var newVal: any = 0;
        var t = { left: 0, top: 0 };
        var absW = jQuery(inObj).outerWidth(true) + 10;
        var absH = jQuery(inObj).outerHeight(true);
        var absT = t.top - 4;
        var absL = isNaN(t.left + parseInt(jQuery(inObj).css('marginLeft'), 10) - 4) ? 0 : (t.left + parseInt(jQuery(inObj).css('marginLeft'), 10) - 4);
        var regClean = new RegExp(' ', 'gi');
        var aripm = new RegExp(/[\+\-\*\/]/);
        var aripmSt = new RegExp(/^[\+\-\*\/]/);
        var toOldVal = false;
        jQuery(inObj).parent().find(".calculateWrapper").remove();

        if (jQuery(inObj).hasClass("roundTo4")) {
            this.options.fixed = 4;
        }
        /* Создаем не указанные элементы */
        var calcwrapper;
        if (!jQuery(inObj).hasClass("showWidth")) {
            calcwrapper = jQuery('<div class="calculatewrapper ui-state-active"></div>');
            jQuery(calcwrapper).append('<div class="actWr">=</div>');
            jQuery(calcwrapper).css({
                'position': 'absolute',
                'left': absL + 'px',
                'top': absT + 'px',
                'visibility': 'hidden',
                'zIndex': '2000',
                //'background': '#cedeea',
                'width': absW + 'px',
                'padding': absH + 6 + 'px 3px 3px 3px'
            });
            jQuery(inObj).after(calcwrapper);
        }


        jQuery(inObj).data("blurFunction", function (e) {


            if (jQuery(inObj).hasClass("showWidth")) {

                //addNotification(jQuery(inObj).data("widthField").is(":focus"));
                if (jQuery(inObj).data("widthField").is(":focus") || jQuery(inObj).data("lengthField").is(":focus") || jQuery(inObj).is(":focus")) {
                    // window.clearTimeout(jQuery(inObj).data("timer"));
                    //addNotification(jQuery(inObj).data("widthField").is(":focus"));
                    return;
                }
            }
            //if (this.options.comment) jQuery(this.options.comment).css({ 'display': 'none' });
            //if (this.options.error) jQuery(this.options.error).css({ 'display': 'none' });
            jQuery(inObj).data("calcWrapper").css({ 'visibility': 'hidden' });
            jQuery(inObj).css({ 'position': 'static' });
            if (jQuery(inObj).hasClass("showWidth")) {
                return;
            }

            if (toOldVal) {
                newVal = oldVal;
            }
            toOldVal = false;

            // if (options.sign) {
            var sign = (newVal < 0) ? ('-') : ('');
            if ($(inObj).val().startsWith('-')) {
                sign = '-';
            }
            //} else {
            //    var sign = '';
            //}
            newVal = Math.abs(typeof newVal == 'string' ? parseFloat(newVal.replace("$", "").replace(/,/g, "")) : newVal);
            if (newVal != 0) {
                $(inObj).val(sign + this.costToString(newVal));
                $(inObj).trigger("change");
                if (this.options.onblur) this.options.onblur(inObj, sign + this.costToString(newVal));
            } else {
                $(inObj).val(this.options.ifnul);
                $(inObj).trigger("change");
                if (this.options.onblur) this.options.onblur(inObj, this.options.ifnul);
            }
        });
        var blr = function () {
            {
                jQuery(inObj).data("timer", setTimeout(jQuery(inObj).data("blurFunction"), 10));
                return;
            }
        };
        if (jQuery(inObj).hasClass("showWidth")) {
            calcwrapper = jQuery('<div class="calculatewrapper ui-state-active"></div>');

            var widthField = jQuery('<span>Width:</span><input  type="number" class="form-control" style="width:' + jQuery(inObj).outerWidth() + 'px" ></input>');
            if (jQuery(inObj).hasClass("lockWidth")) {
                widthField.attr("readOnly", "readOnly");
            }

            var lengthField = jQuery('<span>Length:</span><input  type="number" class="form-control" style="width:' + jQuery(inObj).outerWidth() + 'px" ></input>');
            jQuery(calcwrapper).append(widthField);
            jQuery(calcwrapper).append(lengthField);
            jQuery(calcwrapper).append('<div class="actWr"></div>');
            jQuery(calcwrapper).css({
                'position': 'absolute',
                'left': absL + 'px',
                'top': absT + 'px',
                'visibility': 'hidden',
                'zIndex': '2000',
                //'background': '#cedeea',
                'width': absW + 10 + 'px',
                'padding': absH + 6 + 'px 3px 3px 3px'
            });


            jQuery(inObj).after(calcwrapper);
            widthField.blur(function () {
                //var cont = $("#" + jQuery(inObj).attr("widthFieldId"));
                //cont.val($(this).val());
                //cont.trigger("change");
                component.objectModel[component.modelWidthFieldName] = $(this).val();
                //component.WidthChange.next(component.objectModel[component.modelWidthFieldName]);
                blr();
            });
            widthField.keyup(function () {
                var qty = parseFloat(jQuery(inObj).val());
                var width = parseFloat($(this).val());
                if (!isNaN(qty) && !isNaN(width)) {
                    if (component.UOMName == 'Square Yards') {
                        jQuery(inObj).data("lengthField").val(parseFloat(((qty*9) / width).toFixed(4)));
                    }
                    else {
                        jQuery(inObj).data("lengthField").val(parseFloat((qty / width).toFixed(4)));
                    }

                }
                component.objectModel[component.modelWidthFieldName] = widthField.val();
               // if (component.UOMName == 'Square Yards') {
                  //  component.objectModel[component.modelQuantityFieldName] = jQuery(inObj).val() /9;
                //}
                //else {
                    component.objectModel[component.modelQuantityFieldName] = jQuery(inObj).val();
               // }
           });

            lengthField.blur(function () { blr(); jQuery(inObj).trigger("change"); });
            lengthField.keyup(function () {
                var width = parseFloat(component.objectModel[component.modelWidthFieldName]);
                var length = parseFloat($(this).val());
                if (!isNaN(length) && !isNaN(width)) {

                    if (component.UOMName == 'Square Yards') {
                        jQuery(inObj).val(parseFloat(((length * width)/9).toFixed(4)));
                    } else {
                        jQuery(inObj).val(parseFloat((length * width).toFixed(4)));
                    }
                        component.objectModel[component.modelQuantityFieldName] = jQuery(inObj).val(); 
                }
            });
            jQuery(inObj).data("widthField", widthField);
            jQuery(inObj).data("lengthField", lengthField);
        }
        //if (! jQuery(inObj).data("widthField")) {
        jQuery(inObj).data("calculate", jQuery('<span class="calcaction" style="font-weight:bold;"></span>'));
        jQuery('.actWr', calcwrapper).css({ 'padding': '3px 0px 3px 0px' }).append(jQuery(inObj).data("calculate"));
        //}



        jQuery(inObj).data("calcWrapper", calcwrapper);

        /* Создаем не указанные элементы */
        jQuery(inObj).focus(function () {
            /* Инициализация */
            //window.clearTimeout(options.timer);
            console.log(component.trackWidth);
            if (component.trackWidth != true) {
                return;
            }
            newVal = 0;
            //console.log(component.objectModel)
            oldVal = parseFloat(String(inObj.value).replace(/ /g, '').replace(/,/g, ""));
            (isNaN(oldVal)) ? (oldVal = 0) : oldVal;
            newVal = oldVal; oldVal = 0;
            //jQuery(inObj).css({'position':'relative', 'zIndex':2});
            t = jQuery(inObj).position();
            absT = t.top - 4;
            var mL: any = jQuery(inObj).css('marginLeft');
            mL = isNaN(parseInt(mL, 10)) ? (0) : (parseInt(mL, 10));
            absL = t.left + mL - 6;
            absW = jQuery(inObj).outerWidth(true) + 10;
            absH = jQuery(inObj).outerHeight(true);
            jQuery(inObj).data("calcWrapper").css({ 'left': absL + 'px', 'top': absT - 3 + 'px', 'width': absW + 'px', 'padding': absH + 6 + 'px 6px 2px 6px' });
           // if (this.options.comment) $(this.options.comment).css({ 'display': 'block' });
            /* Инициализация */
            //if (this.options.onfocus) this.options.onfocus(this);

            if (jQuery(inObj).hasClass("showWidth")) {
                jQuery(inObj).css({ 'position': 'relative', 'zIndex': 2002 });
                jQuery(inObj).data("widthField").val(component.objectModel[component.modelWidthFieldName]);
                jQuery(inObj).data("calcWrapper").css({ 'visibility': 'visible' });

                var qty = parseFloat(jQuery(inObj).val());
                var width = parseFloat(component.objectModel[component.modelWidthFieldName]);
                if (!isNaN(qty) && !isNaN(width)) {
                    if (component.UOMName == 'Square Yards') {
                        jQuery(inObj).data("lengthField").val(parseFloat(((qty*9) / width).toFixed(4)));
                    }
                    else {
                        jQuery(inObj).data("lengthField").val(parseFloat((qty / width).toFixed(4)));
                    }
                }

            }


        });
        jQuery(inObj).blur(function (e) {

            {
                jQuery(component.control).data("timer", setTimeout(jQuery(component.control).data("blurFunction"), 10));
                blr();
                return;
            }

            if (toOldVal) {
                newVal = oldVal;
            }
            toOldVal = false;
            //if (options.comment) jQuery(options.comment).css({ 'display': 'none' });
            //if (options.error) jQuery(options.error).css({ 'display': 'none' });
            //jQuery(jQuery(inObj).data("calculate")wrapper).css({ 'visibility': 'hidden' });
            //jQuery(inObj).css({ 'position': 'static' });
            //if (options.sign) {
            //    var sign = (newVal < 0) ? ('-') : ('');
            //} else {
            //    var sign = '';
            //}
            var sign = '';
            newVal = Math.abs(typeof newVal == 'string' ? parseFloat(newVal.replace("$", "").replace(/,/g, "")) : newVal);
            if (newVal != 0) {
                $(inObj).val(sign + component.costToString(newVal,2));
               // if (options.onblur) options.onblur(inObj, sign + costToString(newVal));
            } else {
                $(inObj).val("");
               // if (options.onblur) options.onblur(inObj, options.ifnul);
            }
        });
        jQuery(inObj).keypress(function (e) {
            
        });
        jQuery(inObj).keyup(function (e) {

            if (jQuery(inObj).hasClass("showWidth")) {
                var qty = parseFloat(jQuery(inObj).val());
                var width = parseFloat(jQuery(inObj).data("widthField").val());
                if (!isNaN(qty) && !isNaN(width)) {
                    if (component.UOMName == 'Square Yards') {
                        jQuery(inObj).data("lengthField").val(parseFloat((((qty * 9) / width).toFixed(4)));
                    }
                    else {
                        jQuery(inObj).data("lengthField").val(parseFloat((qty / width).toFixed(4)));
                    }
                }
                return;
            }


            newVal = String(inObj.value).replace(/ /g, '').replace(/,/g, '');
            if (e.keyCode == 27) {
                toOldVal = true;
                if (this.options.onescape) this.options.onescape(inObj, oldVal);
                inObj.blur();
                return;
            }

            var res = aripm.test(newVal);
            if (res) {
                res = aripmSt.test(newVal);
                jQuery(inObj).css({ 'position': 'relative', 'zIndex': 2002 });
                jQuery(inObj).data("calcWrapper").css({ 'visibility': 'visible' });
                if (res) {
                    var tStr = String(oldVal) + String(newVal);
                    try {
                        newVal = parseFloat(eval(tStr));
                        newVal = isNaN(newVal) ? (0) : (newVal);
                        newVal = isFinite(newVal) ? (newVal) : (0);
                        jQuery(inObj).data("calculate").html(this.costToString(newVal));
                    } catch (e) {
                        newVal = 0;
                        jQuery(inObj).data("calculate").html(newVal);
                    }
                } else {
                    var tStr = String(newVal);
                    try {
                        newVal = parseFloat(eval(tStr));
                        newVal = isNaN(newVal) ? (0) : (newVal);
                        newVal = isFinite(newVal) ? (newVal) : (0);
                        jQuery(inObj).data("calculate").html(this.costToString(newVal));
                    } catch (e) {
                        newVal = 0;
                        jQuery(inObj).data("calculate").html(newVal);
                    }
                }
                if (this.options.oncalculate) this.options.oncalculate(newVal);
            } else {
                jQuery(inObj).data("calcWrapper").css({ 'visibility': 'hidden' });
                jQuery(inObj).css({ 'position': 'static' });
                if (isNaN(parseFloat(newVal))) {
                    newVal = 0;
                    jQuery(inObj).data("calculate").html(newVal);
                } else {
                    jQuery(inObj).data("calculate").html(this.costToString(parseFloat(newVal)));
                }
                if (this.options.onendcalculate) this.options.onendcalculate(newVal);
            }
            if (this.options.oninput) this.options.oninput(this, e.keyCode);
        });

        // if (this.options.onready) this.options.onready(this.control);
    }

        //var formGroup = $(`<div class="form-group"></div>`);
        //this.control.before(formGroup);
        //var contDiv = $("<div></div>");
       
        //formGroup.append(`<h4><label class="label label-primary" >${this.label}</label></h4>`)
        //formGroup.append(contDiv);
        //contDiv.append(this.control); 
        
        ////this.control.prepend(`<label class="label label-default" >${this.label}</label>`);
    
}