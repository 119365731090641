import * as Raven  from "raven-js";
//import { isSyntheticPropertyOrListener } from "@angular/compiler/src/render3/util";
import * as moment from "moment";
import * as $  from "jquery";
//import "core-js/features/reflect"


$.fn.extend({
    animateCss: function (animationName, callBack) {
        var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        $(this).addClass('animated ' + animationName).one(animationEnd, function () {
           //
            setTimeout(() => {
                
                
                if (typeof callBack == "function") {
                    callBack($(this)); 
                }
                var aniEle = $(this).removeClass('animated ' + animationName);
                aniEle.removeClass(animationName);
            },500)

           
        });
    }
});



//export * from "../../../typings/jqgrid/jqgrid";
//export * from "../../../typings/Custom/jqgridCustom";
export enum NotificationType { Success, Warning, Error, Dismiss }

export class DyCommon {


    public static EnsureObjectExistsAndSetValue(object, property, value) {


        var props = property.split('.');

        var curObj = object;
        for (var i = 0; i < props.length; i++) {
            if (i == props.length - 1) {
                curObj[props[i]] = value;
            }
            else {
                if (curObj[props[i]] == undefined) {
                    curObj[props[i]] = {};
                }
                    curObj = curObj[props[i]];
             }
        }

        
    }


    public static SetClearedFields = (obj) => {
        obj["clearedFields"] = [];
        for (var key in obj) {
            if (obj[key] == null || obj[key] == undefined) {
                obj["clearedFields"].push(key);
            }
        }

    }

    public static MoveItem(array, old_index, new_index) {
       
        while (old_index < 0) {
            old_index += array.length;
        }
        while (new_index < 0) {
            new_index += array.length;
        }
        if (new_index >= array.length) {
            var k = new_index - array.length;
            while ((k--) + 1) {
                array.push(undefined);
            }
        }
        console.log(new_index + " |" + old_index);
        array.splice(new_index, 0, array.splice(old_index, 1)[0]);
    }

    public static EnsureBlank(items: Array<any>, blankFieldName: string, addNew: () =>any,hasMatch?:(item:any)=>boolean) {

        var foundItem = false;
        var hasBlank = false;
        var lastBlank = -1;
        var lastItem = -1;
        if (items == undefined) {
            items = [];
        }
        if (hasMatch == undefined) {
            hasMatch = (item) => { return true; };
        }

        for (var i = 0; i < items.length; i++) {

            if (hasMatch(items[i])) {
                foundItem = true;
                lastItem = i;
                if (items[i][blankFieldName] == undefined || items[i][blankFieldName] == "") {
                    hasBlank = true
                    lastBlank = i;
                }
            }
        }
        
        if (!foundItem || !hasBlank) {
            items.push(addNew());
        } else if (lastBlank < lastItem) {

            var item = items.splice(lastBlank, 1);
          
            items.push(item[0]);

        }

    }

    public static cloneObject(object: any) {
        return JSON.parse(JSON.stringify(object));
    }

    public static GetDocumentIcon(fileName: string)  {


        var ext = fileName.substr((~-fileName.lastIndexOf(".") >>> 0) + 2);;
        
        var ret = "fa "

        switch (ext.toLowerCase()) {
            case "doc":
            case "docx":
            case "rtf":
                ret += "fa-file-word-o text-primary";
                break;
            case "xls":
            case "xlsx":
            case "xlst":
                ret += "fa-file-excel-o text-success";
                break;
            case "png":
            case "jpg":
            case "jpeg":
            case "gif":
            case "giff":
                ret += "fa-file-image-o text-primary";
                break;
            case "pdf":
                ret += "fa-file-pdf-o text-danger";
                break;
            default:
                ret += "fa-file-o";

        }
        return ret;
    }

    public static newUUID(fromServer = false) {
        if (!fromServer) {
            var d = new Date().getTime();
            if (window.performance && typeof window.performance.now === "function") {
                d += performance.now();
            }
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        }

    };

    public static newGuid(): Promise<string> {
        var p = new Promise<string>((res, rej) => {
            $.get('/api/GetGuid', undefined, function (data) {
                res(data.value);
            });

        });
            return p;
    }
    public static newGuids(amount): Promise<Array<string>> {
        var p = new Promise<Array<string>>((res, rej) => {
            $.get(`api/GetMutipleGuids/${amount}`, undefined, function (data) {
                res(data);
            });

        });
        return p;
    }



    public EvalExpression = (expression: string, object: any,term:string = null):any => {
        //console.log(expression);
        //console.log(object);
        if (expression == undefined) {
            return "";
        }
        
        var res = expression.match(/\{\{(.*?)\}\}/g);
        if (res == undefined) {
            return expression;
        }
        //console.log(res);
        for (var i = 0; i < res.length; i++) {
            var newExp = "";
            try {
                newExp = eval("object." + res[i].replace(/{/g, "").replace(/}/g, "")) +"";
                if (term != undefined) {
                    var foundTerms = newExp.match(new RegExp(term, "ig"));
                    if (foundTerms != undefined) {
                        for (var m = 0; m < foundTerms.length; m++) {


                            newExp = newExp.replace(new RegExp(foundTerms[m], "g"), "<b>" + foundTerms[m] + "</b>");

                        }
                    }

                }

            } catch (e) {
                
                newExp = "";
            }
            //if (newExp == "undefined") {

            //    newExp = "";
            //}

            expression = expression.replace(res[i], newExp);
           
        }

        //if (expression == "null" || expression == "undefined") {
        //    expression = "";
        //}
        //console.log(expression);
        expression = expression.replace(/undefined/g, "");
        //console.log(expression);
        return expression;

    }

    public static makeStringJsonSafe = (str: string):string => {

      return  str.replace(/\n/g, "\\n").replace(/\'/g, "\\'").replace(/\"/g, '\\\"').replace(/\&/g, "\\&").replace(/\r/g, "\\r").replace(/\t/g, "\t").replace(/\\b/g, "\b").replace(/\f/g, "\\f");
    }


    public static UtcDateToLocal(d: Date):Date {

        return moment.utc(d).toDate()
        //h:mm:ss a
    }
    public static UtcDateToLocalString(d: Date): string {

        return moment.utc(moment(d).format("MM/DD/YYYY HH:mm:ss")).local().format("MM/DD/YYYY h:mma");
        
    }


    public static addNotification(notificationType: NotificationType, text: string,onShown?:()=> void) {

        // var n = noty({ text: text, timeout: 15000, type: 'error' });
        var options: NotifyOptions = {
            message: text
        }


        var settings: NotifySettings = {};

        switch (notificationType) {
            case NotificationType.Error:
                settings = {
                    allow_dismiss: true, type: 'danger', animate: {
                        enter: 'animated bounceInUp',
                        exit: 'animated bounceOutDown'
                    }, delay: 15000
                }
                break;
            case NotificationType.Success:
                settings = {
                    allow_dismiss: true, type: 'success', animate: {
                        enter: 'animated rollIn',
                        exit: 'animated rollOut'
                    },
                    delay: 10000
                }
                break;
            case NotificationType.Warning:
                settings = {
                    allow_dismiss: true, type: 'info', animate: {
                        enter: 'animated wobble',
                        exit: 'animated bounceOutDown'
                    },
                     onShown : onShown,
                    delay: 10000

                }
                break;
            case NotificationType.Dismiss:
                settings = {
                    allow_dismiss: true, type: 'info', animate: {
                        enter: 'animated wobble',
                        exit: 'animated bounceOutDown'
                    },
                    onShown: onShown,
                    delay: 150000
                   , 
                    placement: {from:'top',align:'center'}

                }
                options.icon = 'glyphicon glyphicon-warning-sign'
                //options.title = "Notification";
                
                //options.url = "http://www.google.com";
                break;
        }


        

        $.notify(options, settings);
    }

    public static CamelCase(s:string):string {

        if (s != undefined) {

            var firstLet = s.charAt(0).toLowerCase();
            return firstLet + s.substr(1);

        }
        return s;

    }


    public static LogError(err) {
        Raven.captureException(err.originalError || err);
    }

    public static getJsonChanges(a: any, b: any): any {
        var c = { };

        if (b == undefined) {
            return undefined;
        }

        if (a == undefined || b == undefined) {
           
        }
        if (a == undefined && b != undefined) {
            return b;
        }
        

        var hasChanges = false;
        for (var property in b) {
            if (a[property] == undefined && b[property] != undefined && b[property].constructor != Array) {
                c[property] = b[property];
                hasChanges = true;
                continue;
            }

            if (a[property] != undefined && b[property] == undefined && a[property].constructor != Array) {
                if (c["clearedFields"] == undefined) {
                    c["clearedFields"]= [];
                }
                c["clearedFields"].push(property);
                hasChanges = true;
                continue;
            }

            if (b[property] != undefined) {
                if (a[property] == undefined) {
                    c[property] = b[property];
                }
               else if (a[property].constructor == Array) {
                    c[property] = [];
                    var length = a[property].length;
                    var bLength = b[property].length;
                    if (bLength > length) {
                        
                    }
                    for (var i = 0; i < (length > bLength ? length :bLength) ; i++) {
                        var ci = this.getJsonChanges(a[property][i], b[property][i]);
                        if (ci != null) {
                            hasChanges = true;
                            c[property].push(ci);
                        }
                    }
                    if (c[property].length == 0) {
                        delete c[property];
                    }
                }
                else if (a[property].constructor == Object) {
                    c[property] = this.getJsonChanges(a[property], b[property]);
                    if (c[property] != null) {
                        hasChanges = true;
                    } else {
                        delete c[property];
                    }
                }

                else if (a[property] !== b[property]) {
                    c[property] = b[property];
                    hasChanges = true;
                }

            }


        }

        if (!hasChanges) {
            return null;
        }
        c["id"] = b["id"];
        c["accountId"] = b["accountId"];
        return c;
    }

}