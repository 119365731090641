import {Injectable,Inject, OnDestroy} from '@angular/core';


import { HttpClient} from '@angular/Common/http';
import { Subject } from 'rxjs';

@Injectable()
export class RecordTypeService implements OnDestroy {
    private http: HttpClient;
    public recordTypes = [];
    public isPromised: boolean = false ;
    constructor(@Inject(HttpClient) Http: HttpClient) {
        this.http = Http;
        
    }
    private ngUnsubscribe: Subject<any> = new Subject();
    ngOnDestroy(): any {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
        

    public getRecordTypes = (objectName: string): Promise<any> => {
       
        var rec = this.recordTypes[objectName];
        
        if (rec != undefined) {



            return rec;
        }
        else {
            this.recordTypes[objectName] = new Promise(resolve => {
                this.http.get('Dyapi/RecordType/' + objectName)
                    .takeUntil(this.ngUnsubscribe).subscribe(response => {
                        this.recordTypes[objectName] = Promise.resolve(response);
                        resolve(response);
                        
                    })
            });
            return this.recordTypes[objectName];
        }
            }

    }

