import {Directive, Component, ElementRef, Renderer, Input, AfterViewInit, AfterViewChecked , Inject, EventEmitter, Output, Provider, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'
import {ReadOnlyControl} from "../Interfaces/ReadOnlyControl"

export interface DropDownOption {
    value: string,
    text?:string 
}
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR =
    {
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => DyDropDownComponent),
        multi: true
    };

//import {Http, HTTP_PROVIDERS, URLSearchParams} from '@angular/http';

@Component({
    selector: 'DyDropDown',
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    template: `<div *ngIf="readOnly" class="standardFieldWidth form-control-static" >{{value}}</div><select *ngIf="!readOnly" type='text' [(ngModel)]="value"  class="form-control" >
                        <option *ngIf="placeholder != undefined || placeholder != ''" value="{{placeholder}}"  >{{placeholder}}</option> 
                    </select>`

})
export class DyDropDownComponent implements AfterViewInit, ControlValueAccessor,ReadOnlyControl,AfterViewChecked {
    
    elementRef: ElementRef;
    @Input("is-bool") IsBool: boolean
    @Input() readOnly: boolean;
    private hasInit:boolean = false
    private NoDefault:boolean = false;
    //@Input() model: any;
    private _value: any;
    @Input() options:DropDownOption[];
    private datePicker: any;
    private datePickerInput: any;
    private _onTouchedCallback: (_: any) => void = (a) => { };
    @Input() placeholder: string;
    private _onChangeCallback: (_: any) => void = (a) => { };
    @Output() modelChange: EventEmitter<any> = new EventEmitter()
    @Output() Changed: EventEmitter<any> = new EventEmitter();
    constructor( @Inject(ElementRef) elementRef: ElementRef) {
        this.elementRef = elementRef; 
        
        //this.httpService = http;
    }
    ngAfterViewInit() {

        
        var natEle = jQuery(this.elementRef.nativeElement);
        var item = natEle.find("select");
        item.change((e) => { this.modelChange.next(item.val()) });
        var options: DropDownOption[] = this.options
      
        
       
    }

    ngAfterViewChecked(): void {
        var natEle = jQuery(this.elementRef.nativeElement);
        var item = natEle.find("select");
        
        var options: DropDownOption[] = this.options
        //var options: DropDownOption[] = this.options

        if (this.hasInit == false && this.readOnly != true) {
            
            if (this.IsBool) {
                item.append(new Option("Yes", "true", !this.NoDefault, !this.NoDefault))
                item.append(new Option("No", "false", this.NoDefault, this.NoDefault))
            }

            if (options != undefined) {
                
                for (var i = 0; i < options.length; i++) {
                    var option: DropDownOption = options[i];
                    //console.log(option);
                    item.append("<option value='" + option.value + "'>" + option.text + "</option>");
                }
            }
            this.hasInit = true;
        }
    }
    //get accessor
    get value(): any {
        if (this._value == undefined && this.placeholder != undefined) {
            return this.placeholder;
        }

        return this._value;
    };

    //set accessor including call the onchange callback
    set value(v: any) {
        console.log("select changed " + v)
        if (v !== this._value) {
            this._value = v;
            this._onChangeCallback(v);
            this.Changed.emit(v);
        }
    }
    onTouched() {
        this._onTouchedCallback(null);
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        this._value = value;
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }
   
    
}

