import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
//import { HttpClientModule }    from '@angular/Common/http';
import {DyLabelDirective,DyModelDirective,DyWidthField} from "./Directives"
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        //HttpClientModule,
    ],
    declarations: [
        DyLabelDirective, DyModelDirective,DyWidthField
    ],
    exports: [DyLabelDirective, DyModelDirective,DyWidthField]
})
export class DirectivesModule {
}