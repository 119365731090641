import { Directive, Input, Output, EventEmitter } from '@angular/core';
import {Observable } from 'rxjs';


@Directive({
    selector: '[dyModel]',
    host: {
        "[value]": 'dyModel',
        "(input)": "dyModelChange.next($event.target.value)"
    }
})
export class DyModelDirective {
    @Input() dyModel: any; // stored value
    @Output() dyModelChange: EventEmitter<any> = new EventEmitter() // an event emitter
}