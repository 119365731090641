import {Directive, Component, Optional, ElementRef, Renderer, AfterViewInit, Input, OnInit, Inject, EventEmitter, Output, Provider, forwardRef} from '@angular/core';
import {UndefinedToEmptyPipe} from "../Pipes/Pipes"
import {ReadOnlyControl} from "../Interfaces/ReadOnlyControl"
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR =
    {
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => DyTextComponent),
        multi: true
    };
@Component({
    selector: 'dyText',
       
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    template: `<div  ><div  *ngIf="readOnly" class="standardFieldWidth form-control-static rodiv" >{{value}}</div> <input *ngIf="!readOnly"  step='{{stepValue()}}' type="{{type}}" placeholder="{{placeholder}}"  [(ngModel)]="value"  class="standardFieldWidth form-control {{inputClass}}" /></div>`

})
    //[value]="model | undefinedToEmpty" (input)="modelChange.next($event.target.value)"
export class DyTextComponent implements OnInit, AfterViewInit, ControlValueAccessor,ReadOnlyControl {
    elementRef: ElementRef;
    @Input() width ;
    //public placeholder;
    @Input("readOnly") readOnly:boolean;
    @Input() model: any;
    @Input() type: any = "text";
    @Input() step = "0.01";
    @Input()
    control: any;
    @Input() placeholder: string;
    @Input() inputClass: string;
    private _value: any = '';
    @Output() modelChange: EventEmitter<any> = new EventEmitter()

    private stepValue = () => {

        if (this.type == 'number') {
            return this.step;
        }

        return "";

    }

    private _onTouchedCallback: (_: any) => void = (a) => { } ;

    private _onChangeCallback: (_: any) => void = (a)=> {};
    
    constructor( @Inject(ElementRef) elementRef: ElementRef) {
        this.elementRef = elementRef; 
       // ngControl.valueAccessor = this;

        
    }

    ngOnInit() { }

    ngAfterViewInit() {
        if (this.model == undefined || this.model == "undefined") {
            this.model == "";
        }
        if (this.width != undefined) {
            var input = $(this.elementRef.nativeElement).find("input");
            input.removeClass("standardFieldWidth");
            var rodiv = $(this.elementRef.nativeElement).find(".rodiv");
            rodiv.removeClass("standardFieldWidth");
            rodiv.css("width", this.width);
            $(this.elementRef.nativeElement).find("input").css("width", this.width);
        }
        //if (this.type == "tel") {
        //    $(this.elementRef.nativeElement).find("input").attr("pattern", this.width);
        //}
        
    
    }
    //get accessor
    get value(): any { return this._value; };

    //set accessor including call the onchange callback
    set value(v: any) {
       
        if (v !== this._value) {
            this._value = v;
            this._onChangeCallback(v);
          //  console.log("value changed to " + v);
        }
    }
    onTouched() {
        this._onTouchedCallback(null);
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        this._value = value;
        this._onChangeCallback(value);
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }
   
    
}

