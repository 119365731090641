///<reference path="../Interfaces/FolderContentResult.d.ts"/>
import {Directive, Component, ViewChild, ElementRef, Renderer2, Input, OnInit, Inject, EventEmitter, Output, OnDestroy} from '@angular/core';
import {DyModelDirective} from '../Directives/DyModel'
import {TreeViewComponent} from "./Controls"
import {DyCommon,NotificationType} from "../Common"
import { HttpClient,HttpHeaders} from '@angular/Common/http';
import { Subject } from 'rxjs';
//import {Http, HTTP_PROVIDERS, URLSearchParams} from '@angular/http';

declare var filesize:any;
@Component({
    selector: 'DyDocumentControl',
    template: `<div *ngIf="visible"><div class="panel panel-default">
  <div class="panel-heading">
    <div class="buttons">
        <button class="btn btn-success btn-sm" (click)="uploadDoc()">Upload</button>
        <button class="btn btn-warning btn-sm" (click)="newFolderButton()" >New Folder</button>
    </div>
    <div #addFolder class="newFolder form-inline" style="display:none">
            Folder Name <input (input)="checkFolderName()" #newFolderName type="text" class="form-control newFolderName">
            <button class="btn btn-success btn-sm" (click)="createNewFolder()">
                <span class="fa fa-save"></span>
            </button>
            <button class="btn btn-warning btn-sm" (click)="hideNewFolderEntry()">
                <span class="fa fa-times-circle"></span>
            </button>
    </div>
</div>
  <div class="panel-body">
<input class="hide"  type="file" name="files[]"  multiple>

<div class="progress mainprogress" *ngIf="progressValue > 0">
  <div *ngIf="progressValue > 0" class="progress-bar progress-bar-success active progress-bar-striped" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
    <span >{{progressValue}}% Total</span>
  </div>

</div>
<div class="ProgressPlaceholder"></div>
    <DyTreeView [showCheckBoxes]="showCheckBoxes" #tree></DyTreeView>
  </div>
</div></div>`


})
export class DocumentControlComponent implements OnInit,OnDestroy {
    elementRef: ElementRef;
    @ViewChild(TreeViewComponent, { static: false })
    tree: TreeViewComponent;
    @Input()
    objectName: string;
    @Input("encrypted") encrypted = false;
    private BaseFolderId: string;
    public visible: boolean = true;
    @Input()
    id: string
    @Input() beforeUpload: () => Promise<boolean> = () => { return new Promise<boolean>((res, rej) => { res(true) }); }
    private _extraNodes: TreeNode[];
    @Input("extra-nodes") set extraNodes(value) { this._extraNodes = value; this.refreshDocuments() };
    get extraNodes(): TreeNode[] { return this._extraNodes;}
    @Input("showCheckBoxes") showCheckBoxes: boolean = false;
    @Input("createOnInit") createOnInit = true;
    private baseObject :JQuery
    private _progressValue = 0;
    private LasUploadedNodeId;
    public get progressValue() {
        return this._progressValue;
    }

    public set progressValue(value) {
        this._progressValue = value;
        var progress = $(this.elementRef.nativeElement).find(".mainprogress").find(".progress-bar");
        progress.attr("aria-valuenow", value).css("width",`${value}%`);


    }

    public renderer:Renderer2
    public http: HttpClient;
    private ngUnsubscribe: Subject<any> = new Subject();
    ngOnDestroy(): any {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(HttpClient) http: HttpClient,@Inject(Renderer2)renderer:Renderer2) {
        this.elementRef = elementRef;
       
        this.renderer = renderer;
        this.http = http;
    }
    public lastUpload;
    public lastUploadTime:Date;
    ngOnInit() {
        //this.progressValue = 25;
        if (this.createOnInit == true) {
            this.createControl();
        }
       
    }


    public createControl = () => {

        this.baseObject = $(this.elementRef.nativeElement)
        var natEle = this.baseObject;
        this.http.get<DocumentRepo.FolderResult>(`/dyAPI/GetFolderContents/${this.objectName}/${this.id}`)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(res => {
                if (res.folder.id == "$$FOLDERNOTFOUND$$") {
                    this.visible = false;
                    return;
                }
                this.visible = true;
                var contents: DocumentRepo.FolderResult = res;
                this.BaseFolderId = contents.folder.id;
                var nodes: Array<TreeNode> = this.GetTreeNodes(contents);
                //console.log(nodes);
                this.tree.nodes = nodes;
                this.tree.createTree();
                natEle.find("input")
                    .fileupload({
                        datatType: "json",
                        url: '',
                        dropZone: natEle,
                        maxChunkSize: 3000000,
                        add: (e, data) => {

                            this.beforeUpload().then((canUpload) => {
                                var curDate = new Date();
                                if (this.lastUpload != undefined) {
                                    if (data.files[0].name == this.lastUpload[0].name && data.files[0].lastModified == this.lastUpload[0].lastModified)
                                        if (true) {


                                            return;
                                        }
                                }
                                this.lastUpload = data.files;
                                this.lastUploadTime = curDate;

                                var progress = $(`<div class="progress progressChild">
  <div class="progress-bar active progress-bar-striped" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
    <span class="pct"></span> <span>${data.files[0].name}</span>
  </div>
</div>`);
                                natEle.find(".ProgressPlaceholder").append(progress)
                                data.context = progress;

                                //console.log(data)
                                data.url = this.GetFolderToUpload();
                                //console.log(data.url);
                                data.submit();
                            });
                        },
                        progress: (e, data) => {
                            //console.log(data)
                            var value = this.progressValue = parseInt(String(data.loaded / data.total * 100), 10);
                            var progress = $(data.context).find(".progress-bar");
                            progress.attr("aria-valuenow", value).css("width", `${value}%`).find(".pct").html(value + "%");
                            //this.progressValue = parseInt(String(data.loaded / data.total * 100), 10);
                            if (value == 100) {
                                progress.removeClass("active");
                            }
                        },
                        progressall: (e, data) => {


                            this.progressValue = parseInt(String(data.loaded / data.total * 100), 10);
                        },
                        done: (e, data) => {

                            if (this.progressValue == 100) {
                                this.progressValue = 0;
                                natEle.find(".progressChild").remove();
                            }
                            // var uploadedNode = this.tree.getNode(this.LasUploadedNodeId);
                            //  console.log(uploadedNode);
                            // if (uploadedNode == undefined || uploadedNode.extra.obj.id == this.BaseFolderId) {
                            this.refreshDocuments();
                            this.lastUpload = undefined;
                            //} else {
                            //    uploadedNode.nodes == undefined;
                            //    this.expandFolderNode(uploadedNode,true);
                            //}
                        }
                    }
                    );
            });

    }


    public uploadDoc = () => {
        $(this.elementRef.nativeElement).find("input").click();
    }

    public refreshDocuments = () => {
        this.http.get<DocumentRepo.FolderResult>(`/dyAPI/GetFolderContents/${this.objectName}/${this.id}`)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(res => {
                var contents: DocumentRepo.FolderResult = res;
                var nodes: Array<TreeNode> = this.GetTreeNodes(contents);
                this.tree.nodes = nodes;
                this.tree.createTree();
            });

    }

    public getCheckedDocuments = () => {
        var selected: TreeNode[] = this.tree.getCheckedNodes();
        return selected;
    }

    public getSelectedFolder = () => {
        var id;
        var selected: TreeNode = this.tree.getSelectedNode();
        if (selected[0] != undefined && selected[0].extra != undefined && selected[0].extra.type == "folder") {

            id = selected[0].extra.obj.id;
            this.LasUploadedNodeId = selected[0].nodeId;
        } else {
            id = this.BaseFolderId;
        }
        return id;
    }

    public GetFolderToUpload = () => {

        if (this.encrypted) {
            return `/dyAPI/UploadDocument/${this.getSelectedFolder()}/true`
        }
       return `/dyAPI/UploadDocument/${this.getSelectedFolder()}`
               
        
    }

    public GetTreeNodes = (contents: DocumentRepo.FolderResult): Array<TreeNode> => {
        var nodes: Array<TreeNode> = [];
        if (this.extraNodes != undefined) {
            $.each(this.extraNodes, (i, tn: TreeNode) => {
                nodes.push(tn);
            });
        }
        var http = this.http;
        var tree = this.tree;
        var self = this;
        
        $.each(contents.folders, (i, value: DocumentRepo.Folder) => {

           
            
            var dnode: TreeNode = {
                text: self.CreateFolderText(value,dnode),
                selectable: true,
                extra: { type: "folder", obj: value },
                icon: "fa fa-folder fa-2x text-warning",
                onSelected:self.expandFolderNode
            
            };
            //title.click(() => { self.expandFolderNode(dnode) })
            nodes.push(dnode);
        })
        $.each(contents.documents, (i, value: DocumentRepo.Document) => {

            var node: TreeNode = { text:  self.CreateDocumentText(value), selectable:false,  extra: { type: "document", obj: value } }
            nodes.push(node);
        })
        return nodes;

    }

    public expandFolderNode = (node,force?:boolean) => {
       
        var found = this.tree.getNode(node.nodeId);
        if (found.nodes != undefined && force != true) {
            return;
        }

        var ele: JQuery = (<any>found).$el;
        ele.find(".node-icon").removeClass(found.expandedIcon || found.selectedIcon || found.icon)
            .addClass("fa fa-spinner fa-spin fa-2x");


        this.http.get <DocumentRepo.FolderResult>(`/dyAPI/GetFolderContents/${found.extra.obj.id}`).takeUntil(this.ngUnsubscribe).subscribe(fldRes => {
            var subnode: DocumentRepo.FolderResult = fldRes;
            var subNodes = this.GetTreeNodes(subnode);
            var fNode = this.tree.getNode(node.nodeId);
            this.tree.addNode(fNode, subNodes);

        })
    }

    public CreateFolderText = (folder: DocumentRepo.Folder,node:TreeNode) : JQuery => {
        var parentDiv = $("<span style='padding:10px'></span>");
        var title = $(`<span class="folder-title" > ${folder.name} </span>`);
        var icon = parentDiv.parent().parent().parent().find(".node-icon");
        var trash = this.createTrashIcon(title, icon, () => { this.doFolderDelete(folder.id); });
        
        
       
       

        var dblclk = () => {
           //console.log("dbl")
            $(".doc-title").popover("hide");
            var oldnode = this.tree.getNode(parentDiv.parent().parent().attr("data-nodeid"));
            var newNode:TreeNode = oldnode;
            newNode.selectable = false;
            var save = $(`<span class="saveFileName fa fa-save">`);    
            var cancel = $(`<span class="fa fa-ban cancelFileName">`);
           
            var namechang = $("<span></span>");
            var newNameEntry = $(`<input type="text"  value="${folder.name}"/>`);
           
            var returnToNormal = () => {
                namechang.replaceWith(title);
                title.dblclick(dblclk)
            }

            cancel.click(() => {
                newNameEntry.val(folder.name)
                returnToNormal();
            })
            save.click(() => {
                //console.log("save");
                var ele: JQuery = parentDiv.parent().parent();


                var newName = newNameEntry.val();
                if (newNameEntry.val() != folder.name) {
                    var oldIcon = this.SpinNodeIcon(ele);
                    this.http.get(`/dyAPI/RenameFolder/${folder.id}/${newName}`)
                        .takeUntil(this.ngUnsubscribe)
                        .subscribe(
                            res => {

                                folder.name = newName;
                                title.html(newName);

                                this.StopSpinIcon(ele, oldIcon);
                            }
                        )


                }
                returnToNormal();
            });
            namechang.append(newNameEntry).append(cancel).append(save);
            title.replaceWith(namechang);
            newNameEntry.focus();
        }
        title.dblclick(dblclk)

        parentDiv.append(title);
        return parentDiv
    }

    public createTrashIcon = ( title:JQuery, icon:JQuery,deleteFunction:()=>void) => {
        var trashspan = $("<span></span>");
        var trash = $("<span class='fa fa-trash hide text-danger fa-lg'></span>")
            .click(() => {
                trash.addClass("hide");
                TrashYN.slideDown();
                //TrashNo.removeClass("hide");
                $(".doc-title").popover("hide");
            });
        var TrashYN = $("<span style='display:none'></span>");
        var TrashYes = $(`<span class='fa fa-check  text-danger fa-lg'>Yes delete it</span>`).click(() => {
           
            // var oldIcon = this.SpinNodeIcon(ele);
            icon.removeClass().addClass("fa fa-spinner fa-spin fa-2x");

            $(".doc-title").popover("hide");
            deleteFunction()
            this.refreshDocuments();
        });
        var TrashNo = $(`<span style="padding-left:5px" class='fa  fa-times-circle  text-success fa-lg'>No keep it</span>`).click(() => {
            TrashYN.slideUp();
            trash.removeClass("hide");
        });
        title.hover(() => {
            if (!TrashYN.is(":visible")) {
                trash.removeClass("hide")
            }
        },
            () => {
                trash.addClass("hide")
            });
        TrashYN.append(TrashYes).append(TrashNo);
        trashspan.append(trash).append(TrashYN);
        title.append(trashspan)

        return trashspan;

    }

    public CreateDocumentText = (document:DocumentRepo.Document) => {

        var parentDiv = $("<span></span>");
        
        var btnrename = $("<button title='' class='btn btn-danger '><span class='fa fa-edit'></span></button>").click(() => {  });
        var title = $(`<span class="doc-title"  > ${document.name} </span>`);
        var iconopen = $(`<span class="${DyCommon.GetDocumentIcon(document.name)} fa-2x"></span>`);
        var ele: JQuery = parentDiv.parent().parent();
        var trash = this.createTrashIcon( title, iconopen, () => { this.doDocumentDelete(document.id); });
                              
       // var open = $(`<span class="fa fa-external-link-square fa-lg"></span>`)
        var isDouble = false;
       
       // title.append(open);
        title.popover({
            trigger: "hover", title: document.name, html: true,
            content: `Created:${moment(document.dateCreated).fromNow()} - ${moment(document.dateCreated).calendar()}<br/>Size: ${filesize(document.fileSize)}<br/><div style="min-height:100px"><img  src="/dyAPI/GetDocumentThumbnail/${document.id}"/></div>`,
            container: "body", placement: "top", delay: { "show": 1000, "hide": 1000 }
        });
        iconopen.click(() => {
            isDouble = false;
           
            var open = window.open(`/dyAPI/GetDocumentContents/${document.id}/${this.encrypted}`, "_blank", `width=${window.innerWidth},height=${window.innerHeight},menubar=yes,resizable=yes,toolbar=yes`);
            setTimeout(() => {
                    if (isDouble == false) {
                     //open.close();
                    }
                },
                1000);


        });
        
        var dblclk = () => {
            isDouble = true;
            $(".doc-title").popover("hide");
            var save = $(`<span class="saveFileName fa fa-save">`);
            var cancel = $(`<span class="fa fa-ban cancelFileName">`);

            var namechang = $("<span style='display:none'></span>");

            var newNameEntry = $(`<input type="text"  value="${document.name}"/>`);
            namechang.append(newNameEntry).append(cancel).append(save)
            parentDiv.append(namechang);
            var returnToNormal = () => {
                namechang.hide()
                    title.show();
                //title.dblclick(dblclk)
            }

            cancel.click(() => {
                newNameEntry.val(document.name)
                returnToNormal();
            })
            save.click(() => {

                var ele: JQuery = parentDiv.parent().parent();
               
               
                var newName = newNameEntry.val();
                if (newNameEntry.val() != document.name) {
                    var oldIcon = this.SpinNodeIcon(ele);
                    this.http.get(`/dyAPI/RenameDocument/${document.id}/${newName}`)
                        .takeUntil(this.ngUnsubscribe)
                        .subscribe(
                        res => {
                            
                            document.name = newName;
                            title.html(newName);
                            console.log(oldIcon)
                            this.StopSpinIcon(ele, oldIcon);
                        }
                    )


                }
                returnToNormal();
            })
            title.hide();
            namechang.show();
            newNameEntry.focus();
        }
        title.dblclick(dblclk)
        parentDiv.append(iconopen);
        parentDiv.append(title);//.append(btnrename).append(btn);
        return parentDiv;
    }

    public newFolderButton = () => {
        console.log("clicked");
        this.baseObject.find(".buttons").slideUp(500);
        this.baseObject.find(".newFolder").slideDown(500);


    }

    public checkFolderName = () => {
        var folderNameInput = this.baseObject.find(".newFolderName");
        if (folderNameInput.hasClass("requiredField") && folderNameInput.val() != '') {
            folderNameInput.removeClass("requiredField");
        }
    }

    public createNewFolder = () => {
        var folderNameInput = this.baseObject.find(".newFolderName");
        var folderName = folderNameInput.val();
        if ( folderName == '') {
            folderNameInput.addClass("requiredField");
            DyCommon.addNotification(NotificationType.Error, "You must enter a folder name before creating a folder");
            return;
        }

        this.http.get(`/dyAPI/Documents/CreateFolder/${this.getSelectedFolder()}/${folderName}`).takeUntil(this.ngUnsubscribe).subscribe(res => {
            this.refreshDocuments();
            this.hideNewFolderEntry();
        });

    }


    public hideNewFolderEntry = () => {
        
        this.baseObject.find(".newFolder").slideUp(500);
        this.baseObject.find(".buttons").slideDown(500);
        var folderNameInput = this.baseObject.find(".newFolderName");
        folderNameInput.val("");
        folderNameInput.removeClass("requiredField");
    }

    public doDocumentDelete =(docId) => {
        this.http.delete(`/dyAPI/Documents/Document/${docId}`).takeUntil(this.ngUnsubscribe).subscribe(res => {
            this.refreshDocuments();
        })
    }
    public doFolderDelete = (folderId) => {
        this.http.delete(`/dyAPI/Documents/Folder/${folderId}`).takeUntil(this.ngUnsubscribe).subscribe(res => {
            this.refreshDocuments();
        })
    }



    public SpinNodeIcon = (nodeElement: JQuery): string => {
        var nicon = nodeElement.find(".node-icon");
        var oldClass = nicon.attr("class").replace("icon node-icon", "");
        nicon.removeClass(oldClass);

       
        var spinClass = "fa fa-spinner fa-spin fa-2x";
        nicon.addClass(spinClass);
        return oldClass;

    }

    public StopSpinIcon(nodeElement: JQuery, oldIcon: string) {

        var nicon = nodeElement.find(".node-icon");
        var spinClass = "fa fa-spinner fa-spin fa-2x";
        nicon.removeClass(spinClass).addClass(oldIcon);
    }

   

}