import {Directive, Type, Component, ElementRef, ViewChild, ViewContainerRef, Input, ApplicationRef, OnInit, Inject, EventEmitter, Output, ComponentFactoryResolver, Injector, ComponentRef} from '@angular/core';

//import {RouteConfig, ROUTER_DIRECTIVES, Router} from 'angular2/router';
import {DyCommon}    from '../Common' 
import {ConvertOrderComponent}    from '../Components/components' 
import {DyTextComponent}    from '../Controls/Controls' 
import {OrderComponent}    from '../PageComponents/Order' 
import {DyDropDownComponent} from "./DropDown";
declare var service;
declare var BootstrapDialog;
@Component({
    selector: 'DyModal',
    styles: [".modal-dialog {width:90% !important}"],
    //  providers: [HTTP_PROVIDERS],
    
    template: `<div   style="display:none"><ng-template #location >test</ng-template></div>`,
    
})

export class ModalComponent implements OnInit {
    elementRef: ElementRef;
    dialogInstance: any;
    loader:ComponentFactoryResolver;
    pShowDialog: boolean;
    loadedPage:HTMLElement;
    //private router: Router;
    @Input() Buttons: ModalButton[];
    @Output("on-shown") onShown: EventEmitter<any> = new EventEmitter<any>();
    @Output("on-view-init") onViewInit: EventEmitter<any> = new EventEmitter<any>();
    @Output("on-iframe-loaded") onIframeLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Input() ModalType:string = "primary";
    @Input("message-selector") messageSelector: string;
    @Input("title") Title: string;
    @Input() staticContent: string;
    @ViewChild('location',    { read: ViewContainerRef,static:false }) childContentPlace: ViewContainerRef;
    @Output("compnentLoaded") componentLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Input() Component: Type<Component| any>;
    @Input() IframeUrl: string;
    @Input() id: string;
    public Iframe;
    public OnClose: (dialog: any)=> boolean;

    injector: Injector
    //@ViewChild('location') private dynamicTarget: any;
    private dialogRef: any;
    private componentReference: ComponentRef<any>;
    appRef:ApplicationRef;
   @Input() get showdialog(): boolean {
         return this.pShowDialog;
     }
     set showdialog(value) {
        
         this.pShowDialog = value;
         if (value) {
            this.showDialog();
         }
   }
     public resetSettings = () => {
         this.Buttons = undefined;
         this.messageSelector = undefined;
         this.staticContent = undefined;
         this.Title = undefined;
         this.ModalType = "primary";
     }

    @Output() showdialogChange: EventEmitter<any> = new EventEmitter()

    constructor( @Inject(ElementRef) elementRef: ElementRef, @Inject(ComponentFactoryResolver) resolver: ComponentFactoryResolver, @Inject(Injector) injector: Injector) {
        this.elementRef = elementRef; 
        this.loader = resolver;
        this.injector = injector;
        //this.appRef = appRef;
        //document.domain = "datayal.com";
    }

    public showDialog = ():void => {
        
        this.displayDialog()
        
    }

    public hideDialog = () => {
        this.dialogInstance.close();

    }

    public checkIframeLoaded = () => {
    // Get a handle to the iframe element
    //    console.log("check frame")
    //    var iframe = this.Iframe;
    //var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

    //// Check if loading is complete
    //if (iframeDoc.readyState == 'complete' ) {
        //iframe.contentWindow.alert("Hello");
     
        // The loading is complete, call the function we want executed once the iframe is loaded
        this.onIframeLoaded.emit();
        return;
   // }

    // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
    //window.setTimeout(this.checkIframeLoaded, 100);
}

    private getComponent = () => {
       // if (this.loadedPage == undefined) {
            console.log(this)

            //var id = `location-${this.id}`
            //$(this.elementRef.nativeElement).attr("id", id);

            //console.log(this.loader);

            //this.Component = DyTextComponent;
            this.childContentPlace.clear();
            let componentFactory = this.loader.resolveComponentFactory(this.Component);
            this.componentReference = this.childContentPlace.createComponent(componentFactory);


            //this.loader.loadNextToLocation(this.Component, this.childContentPlace).then((compRef) => {

            this.loadedPage = $(this.childContentPlace.element.nativeElement).parent()[0]; // (<ViewContainerRef>(<any>this.componentReference.hostView)._viewContainerRef).element.nativeElement;


            this.componentLoaded.emit(this.componentReference);
        //}
            this.createDialog($(this.loadedPage));
            return this.componentReference;

            // });

        
    }


    private createDialog = (message: JQuery) => {
        console.log(message);
        this.dialogInstance = new BootstrapDialog({
            title: this.Title,
            type: BootstrapDialog["TYPE_" + this.ModalType.toUpperCase()],
            message: $("<div></div>").append(message.show()),
            onshown: (dialogRef) => {
               
                $(".modal").removeAttr("tabindex");
                this.onShown.emit(dialogRef);

            },
            onhide: (dialogRef) => {
                if (this.OnClose != undefined) {
                    this.OnClose(dialogRef);
                }
                
                message.hide();
                $(this.elementRef.nativeElement).append(message);
                this.showdialog = false;
                this.showdialogChange.next(false);
            },
            buttons: this.Buttons,
        });
        this.dialogInstance.open();
        this.onViewInit.emit();

    }

    private displayDialog =() =>{
        
       

        if (this.Component != undefined) {

            this.getComponent();
        }
        else if (this.messageSelector != undefined) {
            this.createDialog($(this.messageSelector));
        }
        else if (this.IframeUrl != undefined) {
            var div = $(`<div></div>`)
            var frame = $(`<iframe style="width:100%;height:${$(window).height() * .70}px;border: 0" src="${this.IframeUrl} "></iframe>`);
            frame.on("load", this.checkIframeLoaded);
            this.Iframe = frame[0];
            div.append(frame);
            this.createDialog(div);

            //this.onShown.subscribe(this.checkIframeLoaded);

            var that = this;
            $(window).resize(() => { 
                
                console.log(frame);
                frame.css("height", $(window).height() * .70)
            });
            
        }
        else {
            this.createDialog($("<div>" + this.staticContent + "</div>"));
        }


      

    }



    ngOnInit() {
        
    }



}

export interface ModalButton {
    label: string;
    id?: string;
    hotkey?: number;
    cssClass?: string;
    action: (dialog?: any) => void;
}
