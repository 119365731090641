import {Pipe, PipeTransform} from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 |  exponentialStrength:10}}
 *   formats to: 1024
*/
@Pipe({ name: 'properCase' })
export class ToProperCasePipe implements PipeTransform {
    transform(value: string, args: string[]): any {
        if (value == undefined) {
            return value;
        }

        return value.toLowerCase()
            .replace(/(^|[\s\xA0])[^\s\xA0]/g, function (s) { return s.toUpperCase(); });
    }
}