import {Directive, Component,  ElementRef, Renderer, Input, OnInit, Inject, EventEmitter, Output, OnDestroy, ViewChild} from '@angular/core';
import { HttpClient} from '@angular/Common/http';
import {SqlDatePipe, ToProperCasePipe, ToPercent} from '../Pipes/Pipes'
import { Subject } from 'rxjs';
import { DyCommon } from '../Common';
import { DxPopupComponent } from 'devextreme-angular';


@Component({
    selector: 'userInfo',
    template: `<div ><img style="height: 50px; " [id]="id + '-image'" class="img-circle" src="/account/getUserImage/{{userid}}" />
<dx-popover
                target="#{{id}}-image" 
                position="top"
                [width]="300" (onShowing)="getData()"
               showEvent="dxhoverstart"
                 [closeOnOutsideClick]="true">{{User |json}}
                <div *dxTemplate="let data = model of 'content'">
                    
<CircleSpinner *ngIf="_user == undefined"></CircleSpinner>
<span *ngIf="_user != undefined">
                   <img class="img-square" src= "/account/getUserImage/{{userid}}" /> {{User.contact.fullName}}<br/>{{User.userName}}<br/> <span [innerHTML]="message"></span></span>
                </div>
            </dx-popover></div>`,
})
export class UserInfoComponent implements OnInit,OnDestroy {
    elementRef: ElementRef;
    private httpService: HttpClient;
    public showPopup:boolean = false
    private _user: any;
    @Input("id") id: string;
    @ViewChild(DxPopupComponent, {static:true}) popover: DxPopupComponent



    get User() {

        if (this._user == undefined) {
            
        }


        return this._user;
    }
    @Input() userid: any;
    @Input("img-style") imgStyle: string = "height: 50px; width: 50px;";
    @Input("message") message: string;
    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(HttpClient) Http: HttpClient) {
        this.elementRef = elementRef;
        this.httpService = Http;
    }
    private ngUnsubscribe: Subject<any> = new Subject();
    ngOnDestroy(): any {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public getData = () => {
        if (this._user == undefined) {
            this.httpService.get("/api/objects/User/" + this.userid).takeUntil(this.ngUnsubscribe).subscribe((res) => {
                this._user = res;
            })
        }
    }
    
    ngOnInit() {
        if (this.id == undefined) {
            this.id = 'e' +DyCommon.newUUID();
        }
        //var ele = $(this.elementRef.nativeElement);
        //var tit = "";
        //ele.css("width", "50px");
        ////ele.popover({
        //    trigger: "hover", title: function () { return tit; }, html: true, content:
        //    "loading..",
        //    container: "body", placement: "top", delay: { "show": 500, "hide": 1000 }
        //});

       // tit = "test";
        //ele.find(".poTitle").text("test");
        //ele = $(this.elementRef.nativeElement).find("div");
        //ele.mouseenter(() => {
        //    this.httpService.get("/api/objects/User/" + this.userid).takeUntil(this.ngUnsubscribe).subscribe(
        //        data => {
        //            console.log("loaded user");
        //            ele.unbind('mouseenter');
        //            var user:any = data;
        //            ele.popover({
        //                trigger: "hover", title: user.contact.fullName, html: true, content:
        //                `<img class="img-square" src= "/account/getUserImage/${this.userid }" /> ${user.contact.fullName}<br/>${user.userName}<br/> ${this.message}`,
        //                container: "body", placement: "top", delay: { "show": 500, "hide": 1000 }
        //            });
        //            ele.popover("show");
        //        },
        //    err => console.error(err),
        //    () => console.log('done')
        //    );
            

        //}
        //);
    }

   
    
}

