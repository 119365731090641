import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
//import { HttpClientModule }    from '@angular/Common/http';
import { PipesModule } from '../Pipes/Pipes.Module';

import { DirectivesModule }    from '../Directives/Directives.Module'; 
import {
    AutocompleteComponent, LineSpinnerComponent, ChangedModifedComponent, CircleSpinnerComponent, DatePickerComponent, DocumentControlComponent, DyDropDownComponent, DyTextComponent, /*DyTextEditorComponent,*/ GridComponent,  ModalComponent, RecordTypeComponent, TreeViewComponent,
UserInfoComponent,YesNoComponent} from "./Controls";
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { DxPopoverModule, DxTemplateModule } from 'devextreme-angular';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
      //  CKEditorModule,
        DxTemplateModule,
        DxPopoverModule

    ],
    exports: [DyTextComponent, ModalComponent, TreeViewComponent, AutocompleteComponent, RecordTypeComponent, YesNoComponent/*, DyTextEditorComponent*/,  DyDropDownComponent, DatePickerComponent, DocumentControlComponent ],
    declarations: [DyTextComponent, ModalComponent, TreeViewComponent, AutocompleteComponent, RecordTypeComponent, YesNoComponent/*, DyTextEditorComponent*/,  DyDropDownComponent, DatePickerComponent, DocumentControlComponent],
 
})
export class SimpleControlsModule{
}