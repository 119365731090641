import { Component, Inject, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';





@Component({
    selector: 'app-root',
    templateUrl:"/AppRoot.html"

})
export class AppComponent implements AfterViewInit,OnDestroy {
    ngAfterViewInit(): void {
    }
    private ngUnsubscribe: Subject<any> = new Subject();
    ngOnDestroy(): any {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    loading: boolean = true;
    constructor() {
    }

   

}