import {Directive, Component,  ElementRef, Renderer, Provider,forwardRef, AfterViewInit, Input, OnInit, Inject, EventEmitter, Output} from '@angular/core';
import {DyModelDirective} from '../Directives/DyModel'
import {ReadOnlyControl} from "../Interfaces/ReadOnlyControl"
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR =
    {
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => DatePickerComponent),
        multi: true
    };

//import {Http, HTTP_PROVIDERS, URLSearchParams} from '@angular/http';



@Component({
    selector: 'DyDatePicker',
    //properties: ["value:value"],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    //events: ["dyModelChange"],
    template: `<div class="container" style="width:250px;margin-right:0px;padding-left:0px">
    
            <div class="form-group">
                <div *ngIf="readOnly" class="standardFieldWidth form-control-static" >{{ value | sqlDate | date: 'MM/dd/yyyy' }}</div>
                <div *ngIf="!readOnly" class='input-group date' >
                    <input type='text' (ngModelChanged)="value = $event" [ngModel]=" value | sqlDate | date: 'MM/dd/yyyy'" class="form-control" />
                    <span class="input-group-addon">
                        <span class="glyphicon glyphicon-calendar" ></span>
                    </span>
                
        </div>
        
    </div>
</div>`
    
})
export class DatePickerComponent implements AfterViewInit, ControlValueAccessor, ReadOnlyControl {
    elementRef: ElementRef;
    @Input() readOnly: boolean;

    @Input("model") model: any;
    private datePicker: any;
    private datePickerInput: any;
    private _value: any = '';
    @Output() modelChange: EventEmitter<any> = new EventEmitter()

    private _onTouchedCallback: (_: any) => void = (a) => { };

    private _onChangeCallback: (_: any) => void = (a) => { };
    constructor( @Inject(ElementRef) elementRef: ElementRef) {
        this.elementRef = elementRef; 
        
        //this.httpService = http;
    }
    ngAfterViewInit() {
        var natEle = jQuery(this.elementRef.nativeElement);
        var item = natEle.find(".date");
        this._onTouchedCallback(null);
        this.datePicker = item.datetimepicker({ format: 'MM/DD/YYYY' }).on("dp.change", (e: any) => {
            if (this.datePickerInput == undefined) {
                this.datePickerInput = natEle.find("input");
            }
            var v = item.data("DateTimePicker").date();
            this.value = this.datePickerInput.val();
                this.modelChange.next(this.value);
                this._onChangeCallback(this.value);
        });
    }
    get value(): any { return this._value; };

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this._onChangeCallback(v);
        }
    }
    onTouched() {
        this._onTouchedCallback(null);
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        this._value = value;
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }

   
    
}