import { Component, AfterViewInit } from '@angular/core';
@Component({
    selector: 'home',
    templateUrl:"/AngularView.html"

})
export class HomeComponent implements AfterViewInit  {
    ngAfterViewInit(): void {

    }
   
}