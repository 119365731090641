import { Directive, Component, ElementRef, forwardRef, Provider, Renderer, Input, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { RecordTypeService } from "../Services/RecordTypeService"
import { ReadOnlyControl } from "../Interfaces/ReadOnlyControl"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR =
{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RecordTypeComponent),
    multi: true
};

@Component({
    selector: 'RecordType',

    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
    template: `<div>
<div *ngIf="readOnly" class="standardFieldWidth form-control-static" >{{Text}}</div> 
<div [class.hidden]="readOnly"><select class="form-group standardFieldWidth"  >
 
     
    </select></div></div>`

})
export class RecordTypeComponent implements OnInit, ReadOnlyControl {
    elementRef: ElementRef;
    public width;
    //public placeholder;
    public recordService: RecordTypeService;
    private _value: string;
    private select: any;
    private _onTouchedCallback: (_: any) => void = (a) => { };
    private _onChangeCallback: (_: any) => void = (a) => { };
    public types: RecordType[]
    @Input() readOnly: boolean;
    @Input() multiple: boolean;
    @Input() placeholder: string;
    @Input() defaultValue: string;
    @Input("object-name") objectName: string;
    @Output() modelChange: EventEmitter<any> = new EventEmitter()
    @Output() ControlCreated: EventEmitter<any> = new EventEmitter()
    @Output() Changed: EventEmitter<any> = new EventEmitter()
    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(RecordTypeService) RecordTypeService: RecordTypeService) {
        this.elementRef = elementRef;
        this.recordService = RecordTypeService;
    }
    public get Text() {
        var res;
        if (this.types != undefined) {
            $.each(this.types, (i, v: RecordType) => {

                if (v.id == this.value) {
                    res = v.name;
                }

            });
        }
        return res;

    }

    public skipChange = false;

    ngOnInit() {

        var defaultId;

        if (this.value == undefined || this.value == "undefined") {
            this.value == "";
        }
        if (this.width != undefined) {
            var input = $(this.elementRef.nativeElement).find("input");
            input.removeClass("standardFieldWidth")
            $(this.elementRef.nativeElement).find("input").css("width", this.width);
        }
        var select = $(this.elementRef.nativeElement).find("select");
        this.select = select;
        if (this.multiple) {
            select.attr("multiple", "multiple");
        }

        this.recordService.getRecordTypes(this.objectName).then(res => {
            this.types = res;

            for (var i = 0; i < res.length; i++) {

                var selected = this.value != undefined && this.value.indexOf(res[i].id) > -1;
                if ((res[i].isDefault == true || this.defaultValue == res[i].name) && (this.value == undefined || this.value == "")) {
                    defaultId = res[i].id;
                    selected = true;
                }
                select.append(new Option(res[i].name, res[i].id, selected, selected));

            }

            select.select2({ placeholder: this.placeholder, theme: "bootstrap", })
                //.val(this.value.split(','))

                .on("select2:select select2:unselect", () => {
                    // console.log(select.select2().val());

                    try {
                        var valuea = select.select2().val();
                        var value: string = "";
                        //$.each(valuea, (index, item) => {
                        //    value += item + "," ;
                        //});
                        value = valuea;
                        this.skipChange = true;
                        this.value = value;//.replace(/(^,)|(,$)/g, "");
                        this.skipChange = false;
                        this.onTouched();

                        var isEmpty = !value;
                        if (!isEmpty) {
                            //value = value.join(",");
                        } else {
                            value = "";
                        }


                    }
                    catch (e) {
                        console.log(e);
                    }


                    var vlu = this.value;
                    try {

                        if (Object.prototype.toString.call(this.value) === '[object Array]') {
                            vlu = this.value.join(",");
                        }
                    }
                    catch (ex) {
                        //do nothing
                    }



                    if (vlu == undefined || vlu == "") {
                        vlu == defaultId;
                        this.value = vlu;


                    }
                   

                    this.Changed.emit(vlu);

                });
            if (this.value == undefined) {
                this.value = select.val();
                

                //select.select2().val(this.model.split(","));


            }
            else {
                select.select2().val(this.value);
            }
            );



        this.ControlCreated.emit({});

    }
    get value(): any { return this._value; };

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            // console.log("value set to " + v);
            // console.log(this._onChangeCallback);
            this._onChangeCallback(v);
            try {
                //this.skipChange = true;
                if (this.skipChange == false) {
                    //     this._value = v;
                    //console.log("value set to " + v);

                    this.select.val(this.value.split(",")).trigger("change");
                }
                //var text = $(this.elementRef.nativeElement).find(".select2-selection__rendered").html(v);
                // this.skipChange = false;
                this._onChangeCallback(v);
            }
            catch (e) {

            }

        }
    }
    onTouched() {
        this._onTouchedCallback(null);
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        this.value = value;
        this._onChangeCallback(value);
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }



}

