// main entry point
import * as $ from "jquery"

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FullAppModule } from '../PageComponents/FullApp.Module'; 
import { enableProdMode } from '@angular/core';
const isLocal: boolean = /localhost/.test(document.location.host);
!isLocal && enableProdMode();
//alert("hi1");
platformBrowserDynamic().bootstrapModule(FullAppModule)
    .then((value) => {
    //$('[data-submenu]').submenupicker();
}, rej => {
    console.log(rej); //$('[data-submenu]').submenupicker();
    //$("#contentarea").css("position", "").css("left", "").addClass("animated bounceInDown");


});