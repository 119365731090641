import {Pipe, PipeTransform} from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 |  exponentialStrength:10}}
 *   formats to: 1024
*/
@Pipe({ name: 'sqlDate' })
export class SqlDatePipe implements PipeTransform {
    transform(value: string, args: string[]): any {
        if (value == '' || value == undefined) {
            return undefined;

        }
        
        return moment(value).toDate();
    }
}