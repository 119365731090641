import { Directive, Component, ElementRef, Renderer, Input, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { HttpClient} from '@angular/Common/http';
import {SqlDatePipe, ToProperCasePipe, ToPercent} from '../Pipes/Pipes'
import { UserInfoComponent } from '../Controls/Controls';
import { DyCommon } from '../Common';



declare var currentUserId: string;
@Component({
    selector: 'changeModifed',
   
   
    template: `<section *ngIf="model.createdById != model.modifiedById" class="form-group">
                    <userInfo id="{{id}}modified1" [userid]="getCreatedUserId()" message="Created on {{model.dateCreated | sqlDate | date:'short'}}" ></userInfo>
                    <section><span class="label label-success">Created</span></section>
                </section>
                <section  *ngIf="model.createdById != model.modifiedById" class="form-group">
                    <userInfo id="{{id}}modified2" [userid]="model.modifiedById" message="Modified on {{model.dateModified | sqlDate | date:'short'}} " ></userInfo>
                    <section><span class="label label-warning">Modified</span></section>
                </section>
                <section *ngIf="model.createdById == model.modifiedById" class="form-group">
                    <userInfo id="{{id}}modified3" [userid]="getCreatedUserId()" message="Created on {{model.dateCreated | sqlDate | date:'short'}} <br/>Modified on {{model.dateModified | sqlDate | date:'short'}}" ></userInfo>
                    <section><span class="label label-success">Created</span></section>
                </section>`,
    
    
})
export class ChangedModifedComponent implements OnInit {
    elementRef: ElementRef;
    
    private httpService: HttpClient;

    private _user: any;

       private _model: any; 
    
    get User() {

        if (this._user == undefined) {
            this._user = { createdById: currentUserId };
        }


        return this._user;
    }
    public id = 'a'+ DyCommon.newUUID();

    @Input()
    set model(value) {
        this._model = value;

    }
    get model() {
        
        //if (this._model.createdById == "00000000-0000-0000-0000-000000000000" || this._model.creagtedById == undefined) {
        //    this._model.createdById = currentUserId;

        //}
        //if (this._model.modifiedById == "00000000-0000-0000-0000-000000000000" || this._model.modifiedById == undefined) {
        //    this._model.modifiedById = currentUserId;

        //}
        return this._model;
    }
    public getCreatedUserId() {
       return this.model.createdById != undefined ?this.model.createdById : currentUserId
    }
  
    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(HttpClient) Http: HttpClient) {
        this.elementRef = elementRef;
        
    }
    ngOnInit() {
        var ele = $(this.elementRef.nativeElement);
        ele.addClass("form-inline");
      
        //console.log("this is the changedmnod" + this.model.createdById);
    }

   
    
}

