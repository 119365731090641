import {Pipe, PipeTransform} from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 |  exponentialStrength:10}}
 *   formats to: 1024
*/
@Pipe({ name: 'toPercent' })
export class ToPercent implements PipeTransform {
    transform(value: any, percision:number = 6): any {

        var pct = parseFloat((value * 100).toFixed(percision));
      return pct + "%";
    }
}